import React from "react";

function TreeComp({ BackgroundCol, treeData }) {
  return (
    <div
      className={`flex items-center justify-center ${
        BackgroundCol ? BackgroundCol : "bg-darkBlue"
      } py-14`}
    >
      <div className="flex items-center justify-center max-w-screen-xl flex-col">
        <div>
          <h2 className="text-2xl md:text-4xl text-center">
            <span className="text-white font-secondary font-bold">The</span>{" "}
            <span className="text-lightBlue font-secondary font-bold">
              Patent
            </span>{" "}
            <span className="text-white font-secondary font-bold">
              translation process
            </span>
          </h2>
          <p className="text-darkMist font-opensans leading-8 text-center py-5 px-3 md:px-0">
            The Tomedes patent translation process is painstaking in its
            adherence to best practices to ensure accuracy and confidentiality.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-3 xl:px-0 pt-24 md:grid-rows-[repeat(5,150px)] md:px-8">
          {treeData
            ? treeData.map(
                ({ heading, img_link, hide, para, circle }, index) => (
                  <div className={`${hide} mb-5 md:mb-0`} key={index}>
                    <div className="flex justify-start relative">
                      <span
                        className={`w-5 h-5 rounded-full bg-white absolute -left-12  ${circle} `}
                      ></span>
                      <h3 className="text-xl font-secondary font-bold text-white">
                        {heading}
                      </h3>
                    </div>

                    <p className="text-darkMist text-base md:pr-8">{para}</p>
                  </div>
                )
              )
            : ""}
        </div>
      </div>
    </div>
  );
}

export default TreeComp;
