export const sliderData = [
  {
    heading: "Utility",
    secondHeading: "Patent",
    details:
      "A utility patent covers new or improved and useful products, processes, or machines. Tomedes patent translation services are provided by translators with expertise in this specialization in diverse domains and various jurisdictions around the world.",
  },
  {
    heading: "Provisional",
    secondHeading: "Patent",
    details:
      "A provisional patent allows the early filing of a legal document in the United States Patent and Trademark Office to establish priority. Tomedes patent translation services are done by expert linguists who understand this type of document for diverse domains and jurisdictions.",
  },
  {
    heading: "Design",
    secondHeading: "Patent",
    details:
      "A design patent relates to the ornamental design of a functional item in a specific industry. Tomedes patent translation services are provided by expert linguists with experience in this and all other types of legal document to protect your intellectual property rights when it comes to design.",
  },
  {
    heading: "Plant",
    secondHeading: "Patent",
    details:
      "A plant patent relates to legal protection for new and unique horticultural products. Tomedes patent translation services are provided by linguists with experience in horticulture, able to describe your innovation to protect your intellectual property rights in all jurisdictions.",
  },
  {
    heading: "Other supported",
    secondHeading: " Patent types",
    details:
      "Tomedes foreign patent translation covers every type of patent and trademark application. Our trained and certified translators have the legal training and certification to deliver accurate and exacting translations which ensure acceptance in all jurisdictions.",
  },
];
