import React from "react";
import Ey from "assets/patentCustomerLogos/EY.svg";
import Google from "assets/patentCustomerLogos/google.svg";
import GreenBerg from "assets/patentCustomerLogos/greenberg-traurig.svg";
import HP from "assets/patentCustomerLogos/hp.svg";
import Kener from "assets/patentCustomerLogos/kanerussell.svg";
import Medtronic from "assets/patentCustomerLogos/medtronic.svg";
import NY from "assets/patentCustomerLogos/newyorklife.svg";
import Perkins from "assets/patentCustomerLogos/perkins-coie.svg";

export default function CustomerLogos({
  BackgroundCol,
  content,
  head,
  colorHead,
  secondHead,
  image,
  thirdHead,
  thirdColorHead,
}) {
  return (
    <section
      className={`${
        BackgroundCol ? BackgroundCol : "bg-darkBlue"
      } py-24 lg:py-32 relative`}
    >
      {image ? (
        <img
          src="https://tomedes.gumlet.io/frontend/images/home-en/Industry_dots.webp"
          alt="dots"
          className="invisible lg:visible absolute top-0 left-0"
        />
      ) : null}

      <div className="container mx-auto px-4">
        <h2 className="text-gray text-3xl md:text-[35px] text-center font-secondary font-bold  tracking-wide lg:max-w-4xl lg:mx-auto leading-[55px]">
          {head ? head : "A Translation Services Company for every"}
          <span className="text-lightBlue">
            {" "}
            {colorHead ? colorHead : "Industry"}
          </span>
          <br /> <span>{secondHead ? secondHead : " "}</span>
          <span
            className={`${thirdColorHead ? thirdColorHead : "text-lightBlue"}`}
          >
            {thirdHead}
          </span>
        </h2>
        <p className="text-lightGray font-opensans text-normal text-center py-4 leading-8 px-10 lg:max-w-5xl lg:mx-auto">
          {content
            ? content
            : "95,000 clients have entrusted Tomedes with their business. These include private individuals, small to medium businesses, and the largest corporations, leading brands, national and local governments. But most importantly: we're here for you. Right now."}
        </p>

        <div className="mx-auto pt-10 text-center">
          <div className="grid grid-cols-2 lg:grid-cols-4 place-items-center lg:gap-20 gap-8 lg:max-w-5xl lg:mx-auto">
            <img src={Ey} alt="" />
            <img src={Google} alt="" />
            <img src={GreenBerg} alt="" />
            <img src={HP} alt="" />
            <img src={Kener} alt="" />
            <img src={Medtronic} alt="" />
            <img src={NY} alt="" />
            <img src={Perkins} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
