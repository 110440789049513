import React from "react";
import fullSuitSvg from "../../assets/new-icons/services-business/fullsuite.svg";
import multiIndustrySvg from "../../assets/new-icons/services-business/multi-industry.svg";
import secureSvg from "../../assets/new-icons/services-business/secure.svg";
import tailorfittedSvg from "../../assets/new-icons/services-business/tailorfitted.svg";
import dedicatedTeamSvg from "../../assets/new-icons/services-business/dedicatedteam.svg";

function Benifits({
  firsttitle,
  BackgroundCol,
  content,
  secondTitle,
  thirdTitle,
  textCol,
  BefitisData,
}) {
  return (
    <>
      <div
        className={` ${
          BackgroundCol ? BackgroundCol : "bg-midBlue"
        } flex items-center justify-center py-24 px-6 lg:px-0`}
      >
        <div className="flex items-center justify-center max-w-screen-2xl flex-col">
          <h2
            className={`text-gray text-3xl md:text-4xl text-center font-secondary font-bold tracking-wide lg:max-w-4xl lg:mx-auto  ${
              content ? "pb-0" : "pb-6"
            }`}
          >
            <span> {firsttitle ? firsttitle : "Added"} </span>
            <span className={`${textCol ? textCol : "text-orange"}`}>
              {" "}
              {secondTitle ? secondTitle : "Benefits"}{" "}
            </span>
            <span>
              {" "}
              {thirdTitle
                ? thirdTitle
                : "of Technical Translation Services"}{" "}
            </span>
          </h2>
          <p className="text-white font-opensans py-6 text-center">
            {content ? content : ""}
          </p>
          <div className="w-full lg:w-8/12 mx-auto px-4 sm:px-0">
            {BefitisData
              ? BefitisData?.map(
                  ({ heading, para, img_link, colHead, icon }) => (
                    <div className="pt-4 border-b-[1px] border-orange mb-12">
                      <div className="pb-8 flex-col sm:flex-row flex items-center">
                        {img_link ? (
                          <img
                            src={img_link}
                            alt={heading}
                            className="mr-6 mb-5 sm:mb-0"
                          />
                        ) : null}
                        <h3
                          className={`${
                            colHead ? colHead : "text-white"
                          }  inset-4 text-2xl tracking-wide font-secondary font-bold `}
                        >
                          {" "}
                          {heading}
                        </h3>
                      </div>
                      <p className="text-darkMist font-opensans leading-snug pb-16 ">
                        {para}
                      </p>
                    </div>
                  )
                )
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Benifits;
