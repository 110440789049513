import { Link } from "@reach/router";
import Reviews from "components/homepage/reviews";
import ServicesGrid from "components/homepage/servicesGrid";
import WhatChooseUs from "components/homepage/whyChooseUs";
import LocationBanner from "components/locationBanner";
import Popular from "components/LocationReusableComp/popular";
import React from "react";
import Benifits from "../Benifits";
import LocalizationMidText from "../localizationMidText";
import { sliderData } from "../slidercomponent/config";
import SliderComp from "../slidercomponent/SliderComp";
import { treeData } from "../treecomponent/config";
import TreeComp from "../treecomponent/TreeComp";
import WhatsAppComp from "../whatsappComp";
import { patentData, PatentLanguages } from "./config";
import CustomerLogos from "./secondComp";

function PatentTranslation() {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="Patent Translation Services"
        content="Tomedes patent translation services protects your intellectual property in every country. We safeguard your innovations and trademarks by providing patent translation for over 120 languages and 950+ language pairs. This patent translation service comes with rapid delivery, 24/7 customer support, and a guarantee for one year."
        formOrangeHead="Get in touch "
        formWhiteHead="with our patent translation firm"
      />
      <CustomerLogos
        BackgroundCol="bg-darkBlue"
        colorHead="Trusted"
        head="Patent Translation Services"
        secondHead="By Law Institutions"
        content="Law institutions, tech firms, and more have placed their trust on Tomedes. Now it's your turn. Join 95,000+ satisfied clients today."
      />
      <LocalizationMidText
        orangeMidText="patent"
        content="translation services allow brands and organizations achieve their global objectives, protecting their intellectual property all the while. We provide guaranteed solutions delivered on-time by our team of experts."
      />
      <Benifits
        firsttitle=" "
        BefitisData={patentData}
        secondTitle="Hallmarks"
        thirdTitle="of Our Patent Translation Services"
        content="This is why companies worldwide trust Tomedes' patent translation services."
        BackgroundCol="bg-darkBlue"
      />
      <SliderComp sliderData={sliderData} />
      <TreeComp treeData={treeData} />
      <WhatsAppComp
        backgroundCol="bg-midBlue"
        btnCol="bg-orange"
        btnLink="/contactus.php"
        btnHoverCol="hover:bg-newOrange-100"
        fHeadCol="text-orange"
        btnText="CONTACT US"
        fHead="Looking"
        secondHead="for patent translation services?"
        content="If you require patent translation services in any language, Tomedes will respond in 30 minutes to your inquiries. Just tell us about your needs and we'll get back to you ASAP."
        btn
      />
      <ServicesGrid
        backgroundCol="bg-darkBlue"
        heading="Specialized Patent translation services for any field"
        details="Tomedes provides patent translation services to companies across the globe in virtually every industry and profession imaginable. Challenge us with your unique requirements!"
        contactUs={
          <Link
            to="/"
            className="bg-orange text-white rounded-3xl uppercase py-2 px-8 mt-6 inline-block  mlap:mb-10"
          >
            contact us
          </Link>
        }
      />
      <Popular
        bottomBorderText
        backgroundCol="bg-midBlue"
        languageCount="Translate your patent into over 120 languages and 950+ language pairs"
        details="With thousands of trained linguists in our network, we can hand-pick the technical translation team that are knowledgeable about the languages and disciplines required."
        bottomHeading="Popular Languages"
        location=" "
        data={PatentLanguages}
        heighT
        footerTitle="More supported languages"
        footerDetails="Tomedes supports translations to and from over 120 languages and 950+ language pairs, covering virtually every language in use in the scientific and industrial world. Whichever language pairs you need, whether you’re translating your patent to an English translation or a Japanese one, we have the right one for you. To protect your valuable intellectual property, we can match you with the patent translation team that’s right for you."
        btnName="SEE OUR SUPPORTED LANGUAGES"
      />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <Reviews backgroundCol="bg-midBlue" />
    </>
  );
}

export default PatentTranslation;
