import Layout from "components/layout";
import PatentTranslation from "components/reusableComponents/patenttranslation/PatentTranslation";
import React from "react";
import SEO from "components/seo";

function index() {
  return (
    <Layout>
      <SEO slug="/patent-translation.php" />
      <PatentTranslation />
    </Layout>
  );
}

export default index;
