export const treeData = [
  {
    heading: "",
    para: "",
    img_link: "",
    hide: "hidden md:inline-block dot-vertical-line",
    circle: "hidden",
  },

  {
    heading: "1. Secure project management",
    img_link: "",
    para: "Tomedes patent translation begins with secure project management. Your project is assigned to a personal management team and linguists, all bound contractually with NDAs.",
    hide: "",
    circle: "inline-block",
  },

  {
    heading: "2. Translation Process",
    img_link: "",
    para: "This same strict legal commitment to information security and confidentiality is applied to every step of the patent translation process, to guarantee that your documents remain private.",
    hide: "mr-5",
    circle: "hidden",
  },

  {
    heading: "",
    para: "",
    img_link: "",
    hide: "hidden md:inline-block",
    circle: "inline-block",
  },

  {
    heading: "",
    para: "",
    img_link: "",
    hide: "hidden md:inline-block dot-vertical-line",
    circle: "hidden",
  },

  {
    heading: "3. LQA",
    img_link: "",
    para: "Linguistic quality assurance is done by the application of linguistic quality analysis to measure and certify the accuracy of each patent translation.",
    hide: "",
    circle: "inline-block",
  },

  {
    heading: "4. Legal analysis",
    img_link: "",
    para: "Linguistic analysis is supplemented with legal analysis by experts trained in intellectual property law and practice in diverse jurisdictions around the world.",
    hide: "mr-5",
    circle: "hidden",
  },

  {
    heading: "",
    para: "",
    img_link: "",
    hide: "hidden md:inline-block ",
    circle: "inline-block",
  },
  {
    heading: "",
    para: "",
    img_link: "",
    hide: "hidden md:inline-block",
    circle: "hidden",
  },

  {
    heading: "5. Secure delivery",
    img_link: "",
    para: "From submission to delivery and in every communication in between, Tomedes patent translation teams and our technology ensure that your documents remain secure and private.",
    hide: "",
    circle: "inline-block",
  },
];
