import Logo1 from "assets/hallmarks/securepatent.svg";
import Logo2 from "assets/hallmarks/accurate.svg";
import Logo3 from "assets/hallmarks/multiindustry.svg";
import Logo4 from "assets/hallmarks/costeffective.svg";

export const patentData = [
  {
    heading: "Secure Patent Translation Services",
    para: "Tomedes patent translation services are secure and confidential. We use end-to-end encryption upon sending as well as secure project management platforms. All our employees and contractors are bound by non-disclosure agreements, ensuring that your sensitive documents remain protected at the highest levels of information security.",
    img_link: Logo1,
  },
  {
    heading: " Accurate and Consistent Terminology",
    para: "We use accurate and consistent terminologies when it comes to your patent specializations. We perform linguistic quality assurance that ensure your patent translations are up to par as well as legal analysis of your intellectual property. We also work closely with your own glossaries, which you can safely send us.",
    img_link: Logo2,
  },
  {
    heading: "Multi-industry Expertise",
    para: "Our translators have the expertise in their fields of specialization, as well as linguistic mastery of the source language and additional languages, which makes them multidisciplinary experts. They're well versed in fields of patent law, and have the know-how for translating your patents with accuracy and clarity.",
    img_link: Logo3,
  },
  {
    heading: " Cost-effective Patent Translations",
    para: "With the combination of state of the art translation technology and our diverse translator database, we offer cost-effective translations that optimize the cost without compromising quality. Our patent translation costs are well-suited for your translation project, and your pocket.",
    img_link: Logo4,
  },
];

export const PatentLanguages = [
  {
    heading: "Japanese",
    heighT: "h-56",
    detail:
      "Japan is an important country in the world of innovation and Tomedes has diverse experience in Japanese patent translation services.",
    link: "/languages/japanese",
  },
  {
    heading: "Chinese",
    heighT: "h-56",
    detail:
      "China is an industrial powerhouse and intellectual property issues are many. Tomedes has rich experience in Chinese patent translation.",
    link: "/languages/chinese",
  },
  {
    heading: "French",
    heighT: "h-56",
    detail:
      "Scientists and innovators from France are ingenious in their patentable ideas. Tomedes bring deep expertise in French patent translation.",
    link: "/languages/french",
  },
  {
    heading: "Russian",
    heighT: "h-56",
    detail:
      "Russia is an important player in global science and industry. Tomedes commands expert resources in Russian patent translation.",
    link: "/languages/russian",
  },
  {
    heading: "Korean",
    heighT: "h-56",
    detail:
      "Korea is one of the rising Asian tigers, and intellectual property protection is important. Tomedes offers linguistic expertise in Korean patent translation.",
    link: "/languages/korean",
  },
];
