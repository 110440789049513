import React from 'react'

function SliderComp({BackgroundCol , heading , para , sliderData}) {
    return (
        <div className={`flex items-center justify-center py-10 ${BackgroundCol ? BackgroundCol : "bg-midBlue"}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full xl:w-7/12 lg:w-9/12 max-w-screen-xl px-6 lg:px-0">
            <div className="">
               <h2 className="text-2xl leading-[30px] md:leading-[50px]  inline-block md:text-4xl font-bold font-secondary text-white">{heading ? heading : "Patent Translation Services For All Types of Patents"}</h2>
               <p className="text-darkMist text-left font-opensans leading-10 pr-0 md:pr-8">{para ? para : "Tomedes patent translation services are provided by expert translators trained in the language of intellectual property law in diverse jurisdictions, covering all types of patents."}</p>
            </div>
            <div className="flex md:ml-10 flex-col h-80 overflow-y-scroll pt-12 mt-16 sm:mt-0">
                {sliderData ? sliderData.map(({heading ,secondHeading , details}) => (
                     <div className="mb-16">
                     <h3 className="font-bold text-2xl font-secondary">
                         <span className="text-orange">{heading}</span>{" "}
                         <span className="text-white">{secondHeading}</span>
                     </h3>
                     <p className="text-darkMist leading-8 md:pr-5">{details}</p>
                 </div>
                )) : null}
            </div>
        </div>    
        </div>
    )
}

export default SliderComp
